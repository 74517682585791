<template>
  <AppHeader v-if="order" class="header">
    <BackButton to="/#handover" />
  </AppHeader>
  <ScreenLoading
    v-if="processing"
    :title="$t('pages.bags-collection.screen-loading-label.success')"
    blur
  />
  <main class="wrapper">
    <BagsSection
      v-if="usedStorageZones?.length && !deliveryUnits?.length"
      :usedStorageZones="usedStorageZones"
      :order="order"
      @onAllItemsPicked="onAllItemsPicked"
    />
    <DeliveryUnitsSection
      v-if="deliveryUnits?.length"
      :deliveryUnits="deliveryUnits"
      :order="order"
      @onAllItemsPicked="onAllItemsPicked"
    />
    <BagsEmptySection
      v-else-if="order?.storageZones.length === 0"
      :order="order"
      @onAllItemsPicked="onAllItemsPicked"
    />
  </main>

  <div class="fixed-footer-content__footer" data-e2e="picking-footer">
    <div class="fixed-footer-content__footer-button">
      <BaseButton
        :disabled="isSubmitAllowed"
        :label="$t('pages.bags-collection.confirm-button.label')"
        btnOutline
        data-e2e="ready-handover-button"
        type="secondary"
        @click="submitHandover"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from 'vue';
import { BaseButton, AppHeader, BackButton } from '@/features/ui';
import ScreenLoading from '@/features/ui/components/ScreenLoading.vue'; // NOTE:  Failed to resolve component, when importing via barrel
import BagsSection from '../components/BagsSection.vue';
import DeliveryUnitsSection from '../components/DeliveryUnitsSection.vue';
import { startHandoverProcess, useOrderStorageZones } from '../composables';
import BagsEmptySection from '../components/BagsEmptySection.vue';

export default defineComponent({
  components: {
    BagsEmptySection,
    DeliveryUnitsSection,
    BagsSection,
    BaseButton,
    AppHeader,
    BackButton,
    ScreenLoading,
  },
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const { order, loadOrder, usedStorageZones, deliveryUnits } =
      useOrderStorageZones();
    const processing = ref(false);

    onBeforeMount(async () => {
      await loadOrder(props.id);
    });

    const submitHandover = async () => {
      if (order.value) {
        await startHandoverProcess(order.value, processing);
      }
    };

    const allItemsPicked = ref(false);
    const onAllItemsPicked = (picked: boolean) => {
      allItemsPicked.value = picked;
    };

    const isSubmitAllowed = computed(
      (): boolean => !(allItemsPicked.value && !processing.value),
    );

    return {
      usedStorageZones,
      deliveryUnits,
      submitHandover,
      order,
      processing,
      isSubmitAllowed,
      onAllItemsPicked,
    };
  },
});
</script>
<style lang="scss" scoped>
.header {
  padding: 16px;
}

.wrapper {
  padding: 0 24px 124px;
}

.bags-list {
  margin-top: 28px;
}
$row-gap: 12px;

.separator {
  margin: 28px 0 0;
  width: 100%;
  height: 1px;

  /* stylelint-disable-next-line color-function-notation */
  background-color: rgb(var(--aldi-mid-blue-40));
  border: none;
}

.error {
  display: grid;
  margin-top: 28px;
  font-size: 16px;
  gap: 28px 0;
  line-height: 1.62em;
}

.error__title {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  gap: 0 8px;
  font: inherit;

  /* stylelint-disable-next-line color-function-notation */
  color: rgb(var(--aldi-red-100));
}
</style>
