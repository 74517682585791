import { useRouter } from 'vue-router';
import { UsedStorageZone } from '@/features/orders/types';
import { sessionStoragePlugin } from '@/features/session-storage';
import { UsedStorageZoneResponse } from '@/features/orders';
import { UseStorageZonesStorage } from '../types';

export function useStorageZonesStorage(): UseStorageZonesStorage {
  const router = useRouter();

  const getStorageKey = (storedDataQuery: string) => {
    const currentRoute = router.currentRoute.value;
    const storageKey = `${String(currentRoute.name)}/${String(
      currentRoute.params.id,
    )}:${storedDataQuery}`;
    return storageKey;
  };

  const labelQuantityStorageKey = getStorageKey('labelsQuantity');

  const saveStorageZonesQuantityToStorage = (
    bagsArray: UsedStorageZone[] | undefined,
  ) => {
    if (bagsArray) {
      const labelsQuantityData = bagsArray.map(
        ({ storageZoneId, quantity }) => {
          return { storageZoneId: storageZoneId.id, quantity };
        },
      );
      sessionStoragePlugin
        .get()
        .setItem(labelQuantityStorageKey, labelsQuantityData);
    }
  };

  const getStorageZonesQuantityFromStorage = () => {
    return sessionStoragePlugin
      .get()
      .getItem<UsedStorageZoneResponse[] | null>(labelQuantityStorageKey);
  };

  return {
    saveStorageZonesQuantityToStorage,
    getStorageZonesQuantityFromStorage,
  };
}
